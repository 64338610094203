import React from "react";
import {alpha, Backdrop, Button, Grow, Icon, IconButton, Stack, Typography} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";

import clap from '../assets/images/hammer.png'
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import pleshDark from "../theme/pleshDark";


export default function Applausometro({url, idPlaying, eventPlaying, user}) {
    const {id} = useParams()

    async function postDataApplausi(e) {
        emojisplosion({
            emojiCount: 5,
            emojis: ['🔨','🧊','🧊'],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=hammer`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = () => (
        <Button id='clapButton' disabled={!eventPlaying} onClick={postDataApplausi} fullWidth
                sx={{borderRadius: '15rem'}}
        >
            <img style={{height: '15.625rem'}} src={clap}/>
        </Button>
    )

    return (
        <Stack mt={5} pb={2} width={'100%'} px={4}
               justifyContent={'center'} alignItems={'center'}
        >
            <Backdrop open={true}
                      sx={(theme) => ({
                          backgroundColor: alpha(pleshDark.palette.background.default, 0.7),
                          zIndex: theme.zIndex.drawer + 100
                      })}
            >
                <Grow in timeout={1500}>
                    <div>
                        <Typography gutterBottom variant={'h4'} fontFamily={'Forever Grotesk'} fontWeight={'light'} maxWidth={'70%'} margin={'auto'}>
                            Tap repeatedly
                            to break the ice!
                        </Typography>
                        <ButtonItem/>
                    </div>
                </Grow>
            </Backdrop>
        </Stack>
    )
}
