import React, {useEffect, useState} from "react";
import Applausometro from "./Applausometro";
import Question from "./Questions/Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {Box, CircularProgress, Grow, Stack} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import CountdownTimerWrapper from "./CountdownTimer";
import RaccontaciEsperienza from "./RaccontaciEsperienza";
import ReactionsLive from "./ReactionsLive";

const LogoBox = ({logo}) => {
    return(
        <Box mt={6} pl={4} display={'flex'} justifyContent={'left'}>
            <img style={{ width:'45%', height:'100%', objectFit:'cover'}} src={logo}/>
        </Box>
    )
}

export default function VoteContent({user, event, loading, userData, setUserData}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==", true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    const [raccontaEsperienza, setRaccontaEsperienza] = useState(localStorage.getItem('raccontaciEsperienza'))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [userData]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        loading ?
            <Box sx={{textAlign: 'center'}}>
                <CircularProgress/>
            </Box>
            :
            <Stack>
                <Stack direction={"column"} justifyContent={"start"} alignContent={"center"}
                       sx={{/*height: '75vh',*/ py: 0, pt:2}}>
                    {event.data().logo &&
                        <LogoBox logo={event.data().logoUrl ? event.data().logoUrl
                            : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                    {event?.data()?.timer && !!event?.data()?.timer?.startedAt &&
                        <Grow in timeout={2000}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <CountdownTimerWrapper label={event.data().timer.label}
                                                       initialTime={event.data().timer.duration}
                                                       isLive={event.data().timer.startedAt}
                                                       startedAt={event.data().timer.startedAt}/>

                            </Box>
                        </Grow>}
                    {
                        event.data().autenticazione &&
                        <DisplayName userData={userData} setUserData={setUserData}
                                     raccontaEsperienza={raccontaEsperienza}/>
                    }

                    {
                        (userData.displayName !== '' && userData.displayName) && !raccontaEsperienza
                        && <RaccontaciEsperienza setRaccontaEsperienza={setRaccontaEsperienza}
                                                 agency={userData.displayName}/>
                    }

                    {!pinnedLoading && !pinnedError && pinned && raccontaEsperienza ?
                        event.data().autenticazione
                            ?
                            (userData.displayName !== '' && userData.displayName) &&
                            <PinnedQuestions userData={userData} questions={pinned}/>
                            :
                            <PinnedQuestions userData={userData} questions={pinned}/>
                        :
                        <></>
                    }
                </Stack>
                {event.data().reactionsLive && raccontaEsperienza ?
                        event.data().autenticazione
                            ?
                            (userData.displayName !== '' && userData.displayName) &&
                            <ReactionsLive url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                            :
                            <ReactionsLive url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                        :
                        <></>}
                {event.data().applausometro && raccontaEsperienza ?
                        event.data().autenticazione
                            ?
                            (userData.displayName !== '' && userData.displayName) &&
                            <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                            :
                            <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                        :
                        <></>
                }
                {(theme && !themeLoading && !themeError && !loading && raccontaEsperienza) ?
                    event.data().autenticazione
                        ?
                        (userData.displayName !== '' && userData.displayName) &&
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()} displayName={userData.displayName || ''}/>
                        :
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()} displayName={userData.displayName || ''}/>
                    :
                    <></>
                }
            </Stack>

    )
}
