import axios from "axios";
import {BEA_API} from "../config";

export default async function getAgencies() {
    let d = []
    await axios.get(`${BEA_API}/bea-stories-world`)
        .then(res => {
            d = res.data
        })
    return d
}