export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const BUCKET = "https://storage.googleapis.com/bea-2024"

//export const BEA_API = "https://matilde-api-dev.plesh.co"
//export const BEA_API_23 = "https://bea-2023-api-5dl3sjiv3q-ew.a.run.app"
export const BEA_API = "https://bea-2024-api-719785460120.europe-west1.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#3673B8', '#3673B8'],
        home_question: ['#3673B8', '#3673B8'],
        applausometro: ['#3673B8', '#3673B8'],
        siNo:['#3673B8', '#3673B8'],
        sentiment: ['#3673B8', '#3673B8'],
        wordcloud: ['#3673B8', '#3673B8'],
        qna: ['#3673B8', '#3673B8'],
    },
    primary: '#3673B8',
    secondary: '#263238',
    accent: '#3673B8',
    text: '#eceff1',
    background:"#1D1D1B",
    poll:{
        default:"rgba(54,115,184,0.48)",
        answered:"#3673B8",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}