import React, {useEffect, useState} from "react";
import {auth, firebase, firestore} from "../firebase/clientApp";
import VoteContent from "../components/VoteContent";
import {useParams} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import axios from "axios";
import {API_URL} from "../config";

export function Loghi({dark = false, displayName}) {
    return (
        <>
            <Box sx={{position: 'fixed', top: 0, left: 0, textAlign: 'left', px: 1.5, pt: 1}}>
                <a href={'https://2ndstage.app'} target={'_blank'}>
                    <Box sx={{width: '5rem', pt: 0.5, filter: dark ? 'brightness(0)' : null}}>
                        <img style={{width: '100%'}} src={require('../assets/images/2ndstage.png')}/>
                    </Box>
                </a>
            </Box>

            {(displayName) &&
                <Box sx={{position: 'absolute', bottom: '10%', left: 0, zIndex: 0}}>
                    <Box sx={{width: '10rem', pt: 0.5, zIndex: 0}}>
                        <img style={{width: '100%', zIndex: 0, opacity: 0.5}} src={require('../assets/images/diamond-laterali/sx.png')}/>
                    </Box>
                </Box>}
            <Box sx={{position: 'absolute', top: '2%', right: 0, zIndex: 0}}>
                <Box sx={{width: '10rem', pt: 0.5, zIndex: 0}}>
                    <img style={{width: '100%', zIndex: 0, opacity: 0.5}} src={require('../assets/images/diamond-laterali/dx.png')}/>
                </Box>
            </Box>

            {<>
                <Box sx={{position: 'fixed', bottom: 0, left: 0, textAlign: 'left', px: 1, pb: 0.5}}>
                    <Box sx={{width: '6.5rem', pt: 0.5}}>
                        <img style={{width: '100%'}}
                             src={require(dark ? '../assets/images/evento/logo-lcw-dark.png' : '../assets/images/evento/logo-lcw.png')}/>
                    </Box>
                </Box>
                <Box sx={{position: 'fixed', bottom: 0, right: 0, textAlign: 'left', px: 1, pb: 0.5}}>
                    <Box sx={{width: '2.5rem', pt: 0.5}}>
                        <img style={{width: '100%'}}
                             src={require(dark ? '../assets/images/evento/logo-bea-dark.png' : '../assets/images/evento/logo-bea.png')}/>
                    </Box>
                </Box>
            </>}
        </>
    );
}

export default function Vote() {
    const {id} = useParams()
    const [user, setUser] = useState(null);
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))
    const [userData, setUserData] = useState({displayName: '', teamName: ''})

    const [loading, setLoading] = useState(true);

    async function loginUser() {
        await auth.signInAnonymously().then(() => {
        }).catch(alert);
    }

    useEffect(async () => {
        setLoading(true)
        await loginUser()
        firebase.auth().onAuthStateChanged(async (user) => {
            setUser(user);
            await axios.post(`${API_URL}/events/${id}/users`, {user: user.uid})
                .then(res =>
                    setUserData({displayName: res.data.displayName ?? '', teamName: res.data.teamName ?? ''})
                )
                .catch()
        })
        setLoading(false)
    }, [])

    if (loading) return <></>

    return (
        <Box>
            {(event && !eventLoading && !eventError) &&
                <Container maxWidth={'sm'} className={'vote'} style={{maxHeight: '100%', paddingInline: 0}}>
                    <Loghi displayName={userData.displayName}/>

                    <VoteContent user={user} event={event} loading={loading} userData={userData}
                                 setUserData={setUserData}/>

                </Container>
            }
        </Box>
    )
}
