import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress, Dialog, DialogContent,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useParams} from "react-router-dom";
import {firebase} from "../firebase/clientApp";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {API_URL, BEA_API} from "../config";
import getAgencies from "../functions/getAgencies";
import {CustomAlert} from "./shared/CustomAlert";

export const DisplayName = ({userData, setUserData, raccontaEsperienza}) => {
    const {id} = useParams()
    const [state, setState] = useState({displayName: '', teamName: ''})
    const [inputValue, setInputValue] = useState('')
    const [error, setError] = useState(false)
    const [setting, setSetting] = useState(false)
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(true)

    const [agencyMap, setAgencyMap] = useState([])

    useEffect(() => {
        getAcencyMap()
    },[])

    const getAcencyMap = async () => {
        setLoading(true)
        let d = await getAgencies()
        setAgencyMap(d)
        setLoading(false)
    }

    useEffect(() => {
        if (userData) {
            setState({displayName: userData.displayName, teamName: userData.teamName})
        }
    }, [userData])

    const handleSetDisplayName = (newAgency) => {
        console.log("newAgency:",newAgency)
        //setState({...state, displayName: event.target.value})
        setState({displayName: newAgency, teamName: newAgency})
    }

    useEffect(() => {
        console.log("state:",state)
    }, [state])

    const handleSetTeam = (event) => {
        //setState({teamName: event.target.value})
    }

    const submitDisplayName = async (event) => {
        event.preventDefault()
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`, {user: uid, displayName: state.displayName, teamName: state?.teamName ?? ""})
            .then(res => {
                /** in modo da avere i punteggi divisi per agency */
                    setUserData({displayName: state.displayName ?? '', teamName: state.displayName ?? ''})
                    setError("")
                    setEditing(false)
            })
            .catch((err) => {
                console.log("NickName già preso!!!", err?.response?.data?.message ?? "There was an error")
                setError(err?.response?.data?.message ?? "There was an error")
            })
            .finally(() => {
                setSetting(false)
            })
    }

    return (
        <Box mx={2}>
            {
                (editing || userData.displayName === '' || !userData.displayName) ?
                    editing ?
                            <Dialog open={editing} fullWidth maxWidth={'md'} onClose={() => setEditing(false)}>
                                <DialogContent>
                                    <DisplayNameForm
                                        error={error}
                                        editing={editing}
                                        setEditing={setEditing}
                                        setError={setError}
                                        userData={userData}
                                        state={state}
                                        agencyMap={agencyMap}
                                        handleSetDisplayName={handleSetDisplayName}
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        submitDisplayName={submitDisplayName}
                                        setting={setting}
                                    />
                                </DialogContent>
                            </Dialog>
                            :
                            <Box mt={'30%'} /*mt={'250px'}*/ mx={2}>
                                <DisplayNameForm
                                    error={error}
                                    editing={editing}
                                    setEditing={setEditing}
                                    setError={setError}
                                    userData={userData}
                                    state={state}
                                    agencyMap={agencyMap}
                                    handleSetDisplayName={handleSetDisplayName}
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    submitDisplayName={submitDisplayName}
                                    setting={setting}
                                />
                            </Box>
                    :
                    <> {
                        raccontaEsperienza ?
                            <Stack sx={{
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                pl: 1,
                                background: 'white',
                                borderRadius: '0 0 0 1rem'
                            }} direction={'row'} alignItems={'center'}>
                                <Typography textAlign={'center'} fontSize={12} color={'black'}>
                                    <b>{agencyMap?.find(a => a.id === userData.displayName)?.name ?? ''}</b>
                                </Typography>
                                <IconButton onClick={() => setEditing(!editing)} color={'primary'}>
                                    <Edit/>
                                </IconButton>
                            </Stack> :null
                        }
                    </>
            }

        </Box>
    )
}

const DisplayNameForm = ({userData, state, editing, setEditing, agencyMap, inputValue, setInputValue, handleSetDisplayName, submitDisplayName, setting, error, setError}) => {
    return (
        <Stack sx={{zIndex: 100}}>
            <Typography textAlign={'center'} fontWeight={'bold'} fontSize={20} sx={{pb: 2, zIndex: 100}}>
                Select your agency
            </Typography>
            <form onSubmit={submitDisplayName}>
                {/*<TextField
                    fullWidth
                    required
                    inputProps={{style: {fontSize: '1.2rem'}}}
                    placeholder="Nickname"
                    id="displayName"
                    value={state.displayName}
                    onChange={handleSetDisplayName}/>*/}
                <Autocomplete
                    value={state?.displayName}
                    onChange={(event, newValue) => {
                        handleSetDisplayName(newValue?.id ?? '');
                    }}
                    inputValue={agencyMap.find(a => a.id === inputValue)?.name ?? inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    disablePortal={false}
                    id="combo-box-demo"
                    options={agencyMap}
                    size={'small'}
                    sx={{width: '100%',}}
                    renderInput={(params) => <TextField {...params} label="Agency" required/>}
                />
                <Stack direction={'row'} pt={2} justifyContent={'center'} spacing={3}>
                    {editing && <Button sx={{color: 'white'}} onClick={() => {
                        handleSetDisplayName(userData.displayName)
                        setEditing(false)
                    }}>
                        Cancel
                    </Button>}
                    {setting ? <CircularProgress/> :
                        <Button variant={'contained'} type={'submit'}
                                disabled={
                                    (editing && userData.displayName === state.displayName) || (!state.displayName)
                                }>
                            Send
                        </Button>}
                    <CustomAlert open={!!error} message={error} setOpen={setError} severity={'error'}/>
                </Stack>
            </form>
        </Stack>
    )
}