import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Backdrop, Box, Button, CircularProgress, Slide, Stack, TextField, Typography} from "@mui/material";
import axios from "axios";
import {TOOLPRESS_API} from "../config";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "./shared/SuccessCheck";

const RaccontaciEsperienza = ({setRaccontaEsperienza, agency}) => {
    let navigate = useNavigate()

    const [animate, setAnimate] = useState(true)
    let progress = useMotionValue(90)

    const [values, setValues] = useState({
        domanda: ''
    })

    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)

    const handleChangeValue = (e, key) => {
        setValues({...values, [key]: e.target.value})
    }

    const postQuestions = async () => {
        setSending(true)
        await axios.post(`${TOOLPRESS_API}/question/AsumWBDO6hA7f1T1envm`, {question: {...values, Agenzia: agency}})
        setSending(false)
        setValues(prevState => ({...prevState, domanda: ''}))
    }

    const sendQuestion = (e) => {
        e.preventDefault()

        postQuestions()
            .then(() => {
                setSuccess(true)
            })
            .finally(() => {
                setSending(false)
                //handleTutorialDone()
            })

    }

    function handleTutorialDone() {
        setAnimate(false)
        localStorage.setItem('raccontaciEsperienza', 'true')
        setTimeout(() => {
            //navigate('/')
            setRaccontaEsperienza(true)
            setAnimate(true)
        }, 400)
    }

    return (<Stack mt={6} /*mt={'210px'}*/ pt={0} alignItems={'center'} justifyContent={'center'}>
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={sending}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Slide in={animate} direction={'right'} unmountOnExit>

            <form style={{textAlign: 'left'}} onSubmit={sendQuestion}>
                {(success) ?
                    <Stack my={2} px={4} alignItems={'center'}>
                        <Typography gutterBottom textAlign={'center'}>Thanks for sharing 😄</Typography>
                        <Box style={{width: '30%', margin: 'auto'}}>
                            <motion.div
                                initial={{x: 0}}
                                animate={{x: 100}}
                                style={{x: progress}}
                                transition={{duration: 0.8}}
                            />
                            <SuccessCheck progress={progress}/>
                        </Box>
                    </Stack>
                    :
                    <Stack px={4} alignItems={'center'} spacing={1} zIndex={100}>
                        <Typography zIndex={100}>
                            Share your experiences with all the BEA participants.
                            Your thoughts will be completely anonymous, don't hold back.
                            We will all read the funniest ones together.
                            <br/>
                            <br/>
                            What are the most tragicomic sentences from the briefs you received?
                            {/*World: What are the most tragicomic sentences from the briefs you received?
                            Raccontaci l’esperienza sulla
                            richiesta più assurda
                            che ti hanno mai chiesto di fare 😄*/}
                        </Typography>

                        <Stack width={'100%'} spacing={1}>
                            <Stack direction={"column"}>
                                <TextField
                                    required
                                    fullWidth
                                    label={'Write here...'}
                                    variant={"outlined"}
                                    onChange={(e) => handleChangeValue(e, 'domanda')}
                                    value={values['domanda']}
                                    multiline
                                    minRows={3}
                                    sx={{borderRadius: '8px important!', mb: 2}}
                                />
                            </Stack>
                        </Stack>
                    </Stack>}
                <Stack px={4} spacing={1}>
                    {!success &&
                        <Button variant={'contained'}
                                type={"submit"}
                        >
                            {'Send'}
                        </Button>}
                    {success &&
                        <Button variant={'contained'}
                                onClick={() => {
                                    setSuccess(false)
                                }}
                        >
                            Send another one
                        </Button>}
                    <Button variant={'outlined'}
                            onClick={handleTutorialDone}
                            sx={{borderRadius: '2rem', color: 'white', width: '100%'}}
                    >
                        Homepage
                    </Button>
                </Stack>
            </form>
        </Slide>
    </Stack>);
}

export default RaccontaciEsperienza