import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, IconButton, Stack, Typography} from "@mui/material";
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import {Download} from "@mui/icons-material";
import axios from "axios";

import getAgencies from "../../../functions/getAgencies";
import {BEA_API, BUCKET} from "../../../config";
import {Loghi} from "../../../pages/Vote";
import {firebase, firestore} from "../../../firebase/clientApp";
import {useParams} from "react-router-dom";

export const AltroQuestion = ({elementPlaying, displayName}) => {
    const {id} = useParams()
    console.log("displayName:",displayName)
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const [agencyMap, setAgencyMap] = useState([])
    const [agencyLoading, setAgencyLoading] = useState(true)

    useEffect(() => {
        getAcencyMap()
    },[])

    const getAcencyMap = async () => {
        setAgencyLoading(true)
        let d = await getAgencies()
        setAgencyMap(d)
        setAgencyLoading(false)
    }

    const getImages = async () => {
        setLoading(true);
        await axios.get(`${BEA_API}/bea-stories-world/${displayName}`)
            .then(res => {
                setImages(res.data.sort((a, b) => a.position - b.position))
            })
        setLoading(false)
    }

    useEffect(() => {
        getImages()
    }, [])

    function dataURLtoBlob(dataURL) {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const byteString = atob(arr[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const view = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            view[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], {type: mime});
    }

    function share() {
        /** TODO: chiamare API di conteggio click */
        let ref = firestore
            .collection('users/plesh/events/' + id + '/queue')
            .doc(elementPlaying.id);

        ref.update({
            shareCount: firebase.firestore.FieldValue.increment(1)
        });

        const name = images[currentSlide]?.filename || 'story-world.jpg';
        let url = (BUCKET +"/bea-world/"+ name)

        const img = new Image();
        img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
        // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
        img.src = url;
        img.onload = async () => {
            // create Canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // create a tag
            if (navigator.share) {
                const dataURL = canvas.toDataURL('image/jpg');

                // Crea un oggetto Blob a partire dalla stringa di dati
                const blob = dataURLtoBlob(dataURL);

                // Crea un oggetto File dal Blob
                const file = new File([blob], 'immagine.jpg', {type: blob.type});
                await navigator.share({
                    files: [
                        file
                    ]
                })
            }
        };
    }

    function downloadImage() {
        /** TODO: chiamare API di conteggio click */
        let ref = firestore
            .collection('users/plesh/events/' + id + '/queue')
            .doc(elementPlaying.id);

        ref.update({
            downloadCount: firebase.firestore.FieldValue.increment(1)
        });

        const name = images[currentSlide]?.filename || 'story-world.jpg';
        let url = (BUCKET +"/bea-world/"+ name)

        const img = new Image();
        img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
        // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
        img.src = url;
        img.onload = () => {
            // create Canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // create a tag
            const a = document.createElement('a');
            a.download = `${name}`;
            a.href = canvas.toDataURL('image/jpeg');
            a.click();
        };
    }

    if (agencyLoading) return (
        <Box sx={{textAlign:'center'}}>
            <CircularProgress/>
        </Box>
    )

    return (
        <Box mt={3}>
            <Loghi dark displayName={displayName}/>
            <Stack sx={{zIndex: 100}}>
                <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'} sx={{zIndex: 100, color: elementPlaying.tipo === 'altro' ? 'black' : ''}}>
                    Share your awards on social media
                </Typography>
            </Stack>
            <Typography variant={'h5'} textAlign={'center'} sx={{zIndex: 100, color: elementPlaying.tipo === 'altro' ? 'black' : ''}}>
                {agencyMap?.find(a => a.id === displayName)['name'] ?? ''}
            </Typography>
            <Box sx={{my:2}}>
                {
                    (loading || agencyLoading) ?
                        <Stack sx={{width: '100%', height: '100%'}} justifyContent={'center'} alignItems={'center'}>
                            <CircularProgress/>
                        </Stack>
                        :
                        <Swiper
                            slidesPerView={1.5}
                            spaceBetween={8}
                            centeredSlides={true}
                            className="mySwiper"
                            onActiveIndexChange={(e) => setCurrentSlide(e.activeIndex)}
                        >
                            {images.length >0 ?
                                images.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <Stack borderRadius={'2rem'}>
                                            <img src={BUCKET +"/bea-world/"+ image.filename}/>
                                        </Stack>
                                    </SwiperSlide>
                                ))
                                :
                                <SwiperSlide>
                                    <Stack borderRadius={'2rem'}>
                                        <img src={`${BUCKET}/bea-world/story-world.jpg`}/>
                                    </Stack>
                                </SwiperSlide>
                            }
                        </Swiper>
                }
            </Box>
            <Stack mt={2} direction={'row'} justifyContent={'center'} alignItems={'start'} spacing={4}>
                {<Box sx={{textAlign: 'center'}}>
                    <IconButton sx={{
                        background: '#3673B8', color: 'black',
                        '&:hover': {
                            background: '#3673B8',
                            color: 'black',
                        },
                        "&:active": {
                            background: '#3673B8',
                            color: 'black',
                        },
                    }} size={'large'} onClick={downloadImage}>
                        <Download/>
                    </IconButton>
                    {!navigator.share && <Typography sx={{pt: 1}}>
                        Download and share on your social media
                    </Typography>}
                </Box>}
                {navigator.share && <Box sx={{textAlign:'center'}}>
                    <Button variant={'outlined'}
                            sx={{borderRadius: '2rem', color: 'black', width: '100%'}}
                            size={'large'}
                                onClick={share}
                    >
                        Share
                    </Button>
                </Box>}
            </Stack>
        </Box>
    )
}